/* .navbar {
  margin-left: 10px;
  margin-right: 10px;
  border-left: 1px solid #3A1246;
  border-right: 1px solid #3A1246;
  border-bottom: 3px solid #3A1246; 
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
} */

.navbar-light {
  background: rgb(248, 238, 255, 0.9);
} 

.navbar-brand img {
  height: 50px;
  width: auto;
}

.nav-link {
  cursor: pointer;
}

.icons {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.active button  {
  color: #3A1246 !important;
  font-weight: bold;
}

.icons .menu-icon {
  height: 25px;
  width: auto;
}

.icons button {
  background: 0%;
  border: 0;
  color: rgb(109, 11, 155);
  display: flex;
  flex-direction: row; 
  align-items: flex-end;
}

.icons button:hover {
  color: #3A1246;
  cursor:pointer
}

.icons:hover .icon-settings {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}