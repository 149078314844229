.login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container img {
    height: 100px;
    widows: auto;
}

.login-container form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
}

.login-container form input {
    margin-top: 10px; 
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
}

.login-container form input::placeholder {
    color: #999;
}

.login-container form button {
    margin-top: 20px;
    border: 0;
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    background: #3A1246;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
}