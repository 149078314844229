.cake-name {
    margin-bottom: 20px;
}

.cake-info {
    text-align: left;
}

.mb-4 {
    height: 93%;
}

.buttons {
    height: 300px;
    flex: 1;
    text-align: center;
    align-content: center;
    align-items: center;
    display: grid;
}

.buttons button {
    background: 0%;
    border: 0;
}

.card-img-top {
   text-align: center;
   background: rgb(248, 238, 255);
}

.card-img-top img {
    padding: 10px;
 }

.add-img {
    height: 200px;
    width: auto;
    cursor: pointer;
    color: #3A1246;
}

.main-container .add-img:hover {
    transform: translateY(-5px);
    transition: all 0.2s;
}