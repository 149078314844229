* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  background: #f5f5f5;
}

body, input, button {
  font-family: Arial, Helvetica, sans-serif;
}

.main-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  margin-top: 50px;
}

.menu .logo {
  height: 100px;
  width: 100%;
  margin: 0;
  text-align: center;
}

.menu .logo img {
  height: 100px;
  width: auto;
}

.container {
  text-align: left;
}

.menu {
  width: 100%;
  display: grid;
}

.menu .icons {
  height: auto;
  width: 100%;
  align-items: right;
  text-align: right;
  float: right;
}

.menu .icons button {
  background: 0%;
  border: 0;
}

.menu .icons .menu-icon {
  height: 40px;
  width: auto;
  color: rgb(196, 196, 196);
  margin-right: 5px;
}

.menu .icons .menu-icon:hover {
  color: #3A1246;
  cursor:pointer
}

.menu .icon-settings:hover {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.main-container .update-icon  {
  color: rgb(196, 196, 196);
  margin-right: 10px;
  float: right;
}

.main-container .del-icon  {
  color: rgb(196, 196, 196);
  margin-right: 10px;
  float: right;
}

.main-container .del-icon:hover  {
  color: #dc3545;
  cursor: pointer;
}

.main-container .update-icon:hover  {
  color: #28a745;
  cursor: pointer;
}

.btn-primary {
  border: 0;
  border-radius: 4px;
  font-size: 20px;
  background: #3A1246;
  font-weight: bold;
  color: #FFF;
  cursor: pointer;
}

.main-container input:invalid {
  -moz-mox-shadow: none;
}