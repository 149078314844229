.preco-total-span {
    background-color: #3A1246;
    color: #FFF;
    font-size: 20px;    
}

.preco-total-input {
    font-size: 20px;    
}

.btn-add-insumo {
    margin-top: 20px;
    border: 0;
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    background: #3A1246;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
}

.btn-add-bolo {
    margin-top: 8px;
    border: 0;
    border-radius: 4px;
    height: calc(1.5em + .75rem + 2px);
    font-size: 20px;
    background: #3A1246;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
}

.btn-save-categ {
    border: 0;
    border-radius: 4px;
    font-size: 20px;
    background: #3A1246;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
}

@media screen and (max-width: 995px) {
    .needless-col {
        display: none !important;
    }
}